import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountSignature, SignaturePlans } from '../pages/signature/signature.interface';
import { LocalStorageUtil } from '../util/local-storage-util';
import { DigitalManagerProducts } from '../pages/signature/digital-manager.interface';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  constructor(private apiService: ApiServiceService) { }

  accountSignatureInfo: AccountSignature = null;

  getAccountSignature(): Observable<{
    data: AccountSignature
  }>{
    try{
      return this.apiService.get(`/subscription`).pipe(
        map(
          (signature: any) => {
            if(signature) {
              this.accountSignatureInfo = {
                active: signature.data?.active,
                suspended: signature.data?.suspended,
                identifier: signature.data?.identifier,
                digitalManagerData: signature.data?.digitalManagerData,
                planName: signature.data?.planName,
              }

              LocalStorageUtil.setSignature({
                active: signature.data?.active,
                suspended: signature.data?.suspended,
                identifier: signature.data?.identifier,
                digitalManagerData: signature.data?.digitalManagerData,
                planName: signature.data?.planName,
              })

              return signature;
            }else return null;
          }
        )
      );

    }catch(error){
      return;
    }
  }

  getPlans(): Promise<DigitalManagerProducts>{
    return this.apiService.get(`/subscription/plans`).toPromise();
  }

  getAccountQuantity(planName = 'Plano Básico'): Observable<any>{
    return this.apiService.post(`/subscription/users-quantity?planName=${planName}`, {
      planName
    });
  }

  createSignature(plan: SignaturePlans, cardToken = null, installments = 1){
    return this.apiService.post('/subscription/create', { planIdentifier: plan.identifier, maxUsers: plan.maxUsers, cardToken, installments });
  }

  changeSubscription(plan: SignaturePlans, cardToken = null, installments = 1): Observable<any> {
    return this.apiService.post(`/subscription/change-plan`, { newPlanIdentifier: plan.identifier, maxUsers: plan.maxUsers, cardToken, installments });
  }

  addUsersToSignature(data: any): Observable<any> {
    return this.apiService.post(`/subscription/add-users`, data);
  }

  getAccountInvoices(data: any): Observable<any> {
    return this.apiService.post(`/subscription/invoices`, data);
  }

  cancelSignature(reason: {
    option: string,
    observation
  }): Observable<any> {
    const signature = LocalStorageUtil.getSignature();

    if(!signature.digitalManagerData.subscription_code) return;
    
    return this.apiService.post(`/subscription/cancel`, { 
      subscriptionCode: signature.digitalManagerData.subscription_code,
      reason
    });
  }
}
