import { Component, OnInit } from '@angular/core';
import { AccountSignature, PlanFeature, SignaturePlansIdentifiers } from './signature.interface';
import { SignatureService } from 'src/app/services/signature.service';
import { LoginService } from 'src/app/services/login.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationDialog, UiConfirmationDialogComponent } from 'src/app/shared/components/ui-confirmation-dialog/ui-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Account, AccountService } from 'src/app/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  constructor(
    private signatureService: SignatureService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private router: Router,
  ) { }

  loading = true;
  signature: AccountSignature = null;
  signaturePlans = null;

  additionalUsersInfo: PlanFeature;

  fg = new UntypedFormGroup({
    quantity: new UntypedFormControl(null),
    value: new UntypedFormControl(null),
  });

  showDocumentMessage = true

  ngOnInit(): void {
    this.getSignatureInformations();
  }

  async getSignatureInformations() {
    this.loading = true;

    try {
      const resp = await this.signatureService.getAccountSignature().toPromise();
      this.signature = resp.data;

      if(!this.signature?.digitalManagerData || !this.signature){
        const resp = await this.signatureService.getPlans()
        this.signaturePlans = resp.data
      }

      this.loading = false
    } catch (error) {
      this.loginService.showMessage('Erro ao carregar informações de assinaturas e planos');
      this.loading = false;
    }
  }

  openIntercomChat() {
    this.loginService.showIntercom();
  }

  async goToPlan(url: string) {
    if (!url) {
      this.loginService.showMessage('Plano indisponível no momento');
      return;
    }

    if(!this.showDocumentMessage) {
      window.open(url, '_blank');
      return;      
    }

    let accountInfo: Account = await this.accountService.getAccount().toPromise()

    const maskedDocument = accountInfo.documentType == 'CPF' ? 
      accountInfo.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : 
      accountInfo.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

    const dialogRef = this.dialog.open(UiConfirmationDialogComponent, {
      data: {
        title: 'Atenção!',
        question: `O documento que pretende usar para assinar é o ${maskedDocument}?`,
        confirm: 'Sim',
        cancel: 'Não, preciso alterar'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialog) => {
      if (result.confirm) {
        window.open(url+'?doc='+accountInfo.document, '_blank');
      } else {
        this.router.navigate(['/contractor']); 
      }

      this.showDocumentMessage = false
    })
  }

  cancelSignature(){
    const cancelReasons = [
      'Vou mudar para outra ferramenta/serviço',
      'Reestruturação interna (mudanças de sócios, equipe ou estratégia)',
      'Senti falta de funcionalidade(s) que preciso',
      'Não estou usando o suficiente / Não usei',
      'Vou dar uma pausa, volto logo',
      'Outros'
    ]

    const dialogRef = this.dialog.open(UiConfirmationDialogComponent, {
      data: {
        title: 'Cancelar assinatura',
        question: `Por favor, selecione um motivo de cancelamento`,
        confirm: 'Cancelar assinatura',
        cancel: 'Voltar',
        options: cancelReasons,
        textField: {
          label: 'Comentário adicional',
          value: ''
        },
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialog) => {
      if (result) {
        this.loading = true

        this.signatureService.cancelSignature({
          option: result.selected,
          observation: result.textField.value
        }).subscribe(
          (resp) => {
            this.loginService.showMessage('Assinatura cancelada com sucesso');
            this.getSignatureInformations();
          },
          (error) => {
            this.loading = false
            this.loginService.showMessage('Erro ao cancelar assinatura');
          })
      }
    })
  }
}
