import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TakeAccountService } from '../../services/take-account.service';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user.interface';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-take-account',
  templateUrl: './take-account.component.html',
  styleUrls: ['./take-account.component.scss']
})
export class TakeAccountComponent {
  form: FormGroup;
  accountTaken: AccountInfo = null;
  users: User[];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly takeAccountService: TakeAccountService,
    private readonly loginService: LoginService,
  ) {
    this.form = this.formBuilder.group({
      cpfOrEmail: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.takeAccountService.takeAccountAccess(this.form.value)
      .subscribe({
        next: (response) => {
          this.accountTaken = response.accountInfo;

          LocalStorageUtil.setAccountInfo(response.accountInfo);
          LocalStorageUtil.setToken(response.accessToken);
          this.router.navigate(['/indications']);

          window.location.reload();
        },
        error: (err) => {
          console.error(err);
          this.loginService.showMessage(err.error.message);
        }
      });
  }
}
