import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-ui-confirmation-dialog',
  templateUrl: './ui-confirmation-dialog.component.html',
  styleUrls: ['./ui-confirmation-dialog.component.scss']
})
export class UiConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UiConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialog,
    private loginService: LoginService,
  ) {
    if (!data.confirm) {
      data.confirm = 'Confirmar';
    }

    if(data.options) {
      data.selected = data.options[data.options.length - 1];
    }
  }

  action(): void {
    this.dialogRef.close(this.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

export interface ConfirmationDialog {
  cancel?: string;
  confirm?: string;
  title: string;
  question: string;
  options: Array<any>;
  textField: any;
  selected?: any;
}
