export enum SignaturePlansIdentifiers {
  // 5 indicações por ano
  // 1 usuário
  FREE = 'free',

  // Plano legado na Iugu
  // Sem limite de indicações
  // 2 usuários
  ESSENTIAL = 'essential',

  // Sem limite de indicações
  // 5 usuários
  PLUS = 'plus',

  // 10 indicações por ano
  // 2 usuários
  INTERMEDIATE = 'intermediate',
}

export interface SignaturePlans {
  identifier: SignaturePlansIdentifiers,
  name: string,
  value: string,
  maxUsers: number,
  schedulePrice: string,
  features: PlanFeature[]
}

export interface AccountSignature {
  active: boolean;
  suspended: boolean;
  expiresAt?: string;
  createdAt?: string;
  identifier?: SignaturePlansIdentifiers;
  hasDefaultPaymentMethod?: boolean;
  addedUsers?: number;
  digitalManagerData?: {
    cancel_at_cycle_end: boolean,
    cancelled_at: number,
    charged_every_days: number,
    charged_times: number,
    contact: {
        doc: string,
        email: string,
        id: string,
        name: string,
        phone_local_code: string,
        phone_number: string,
    },
    contracts: number,
    created_at: number,
    id: string,
    is_cycling: boolean,
    last_status: string,
    last_status_at: number,
    next_cycle_at: string,
    own_engine: true,
    payment_method: string,
    product: {
        id: string,
        marketplace_id: string,
        marketplace_name: string,
        name: string,
    },
    started_at: number,
    subscription_code: string,
    trial_finished_at: number,
    trial_started_at: number,
    updated_at: number;
  },
  planName: string
}

export interface PlanFeature{
  identifier: string, 
  value: number
  name: string
}