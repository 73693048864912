import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Views } from '../views';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { SafeUrl } from '@angular/platform-browser';
import { AccountService } from 'src/app/services/account.service';
import { Location } from '@angular/common';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { Router } from '@angular/router';
import { SignatureService } from 'src/app/services/signature.service';

@Component({
  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  styleUrls: ['./contractor.component.scss']
})
export class ContractorComponent extends Views implements OnInit {

  accountImage: any;
  accountNewImage: any;
  accountNewImageUrl: SafeUrl;

  constructor(
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private accountService: AccountService,
    private location: Location,
    private router: Router,
    private signatureService: SignatureService,
  ) { 
    super()
  }

  ngOnInit(): void {
    this.campos = {
      document: [Validators.required],
      documentType: [Validators.required],
      name: [Validators.required],
      email: [Validators.required],
      mobile: [Validators.required],
    }

    this.initForm(this.fb)

    this.getAccount()
  }

  onChangeEmail(){
    if(!this.loginService.isEmailPatternValid(this.fg.controls.email.value)) {
      this.fg.controls.email.setErrors({
        invalid: true
      })
    }
  }

  async onChangeDocument(){
    if(this.fg.controls.documentType.value == 'CPF') {
      if(!this.loginService.isCpfPatternValid(this.fg.controls.document.value)) {
        this.fg.controls.document.setErrors({
          invalid: true
        })
      }
    } else {
      if(!this.loginService.isCnpjPatternValid(this.fg.controls.document.value)) {
        this.fg.controls.document.setErrors({
          invalid: true
        })
      }
    }
  }

  getAvatarConta(): string {
    const name = this.fg.controls.name.value
      ? this.fg.controls.name.value.split(' ')
      : [];
    
    if (name.length > 1) {
      return `${name[0].charAt(0)}${name[1].charAt(0)}`
    } else if (name.length > 0) {
      return name[0].charAt(0)
    } else {
      return ''
    }
  }

  save(){
    this.validateAll()

    if(this.fg.invalid) {
      this.loginService.showMessage('Preencha todos os campos corretamente')
      return;
    }

    this.loading = true

    this.accountService.updateAccount(this.fg.value).subscribe(async resp => {
      const accountInfo = LocalStorageUtil.getAccountInfo()
      accountInfo.accountName = this.fg.controls.name.value
      LocalStorageUtil.setAccountInfo(accountInfo)

      if(resp.update) {
        this.loginService.showMessage('Conta sincronizada com a Digital Manager com sucesso!')

        this.router.navigate(['/signature'])
      } else {
        this.loginService.showMessage('Informações atualizadas com sucesso!')
        this.goBack()
      }
    }, (error) => {
      this.loginService.showMessage('Erro ao atualizar dados do contratante')
      this.loading = false
    })
  }

  goBack(){
    this.location.back()
  }

  getAccount(){
    this.accountService.getAccount().subscribe(resp => {
      this.loadFormValues(resp)

      this.loading = false
    }, (error) => {
      this.loginService.showMessage('Erro ao buscar informações da conta')
      this.loading = false
    })
  }

}
