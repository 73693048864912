import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { UiChangePasswordComponent } from '../ui-change-password/ui-change-password.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-ui-main-menu',
  templateUrl: './ui-main-menu.component.html',
  styleUrls: ['./ui-main-menu.component.scss']
})
export class UiMainMenuComponent implements OnInit {
  @Input() userRegistration = null;

  account: AccountInfo

  menu = [
    {
      label: 'Clientes',
      icon: 'users',
      submenu: [
        { label: 'Cadastro', link: '/patients' },
        { label: 'Agendamento de Consulta', link: '/indications' }
      ]
    }
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.loginService.startIntercom(LocalStorageUtil.getAccountInfo());

    if(this.userRegistration){
      this.account = this.userRegistration;
      return;
    }else{
      this.account = LocalStorageUtil.getAccountInfo();

      if(this.account.admin)
        this.menu.push({
          label: 'Conta',
          icon: 'credit-card',
          submenu: [
            { label: 'Assinatura', link: '/signature' },
            { label: 'Contratante', link: '/contractor' },
            // { label: 'Faturas', link: '/invoices' },
            { label: 'Usuários', link: '/users' },
          ]
        });
    }

    if (this.account.superAdmin) {
      this.menu.push({
        label: 'Administrativo',
        icon: 'user',
        submenu: [
          { label: 'Assumir conta', link: '/take-account' },
          { label: 'Troca de plano', link: '/change-plan' }
        ]
      });
    }
  }

  onClick(item: { link: string }){
    if(item.link) {
      this.router.navigate([item.link])
    }
  }

  logout(){
    this.loginService.stopIntercom();
    this.router.navigate(['/login'])
    LocalStorageUtil.logout()
  }

  firstName(name: string) {
    return name.split(' ').length > 0 ? name.split(' ')[0] : name
  }

  goToProfile(){
    const token = LocalStorageUtil.getToken();
    this.router.navigate([`/profile/${token}`])
  }

  resetPass(){
    const dialogRef = this.dialog.open(UiChangePasswordComponent)

    dialogRef.afterClosed().subscribe(
      res=>{}
    )
  }

}
