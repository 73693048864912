import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';

export interface Account {
  id: number,
  documentType: 'CPF' | 'CNPJ',
  document: string,
  name: string,
  email: string,
  mobile: string,
  signatureId: number
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private apiService: ApiServiceService
  ) { 
    
  }

  public getAccount(): Observable<Account> {
    return this.apiService.get(
      `/account`  
    );
  }

  public updateAccount(account: Account): Observable<{
    update: boolean
  }> {
    return this.apiService.put(
      `/account`, account  
    );
  }
}
