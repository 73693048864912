export interface IIndication {
  communicarePatientId: number;
  communicarePaymentLink: number;
  communicareScheduleId: number;
  communicareTeleconsultationURL: string;
  cpf: string;
  documentUrl: string;
  id: number;
  patient: string;
  patientId: number;
  scheduleDate: string;
  status: IndicationsScheduleStatus;
}

export enum IndicationsScheduleStatus{
  WAITING_SCHEDULE = 'Aguardando agendamento',
  SCHEDULED = 'Agendado',
  WAITING_RESCHEDULE = 'Aguardando reagendamento',
  WAITING_PAYMENT= 'Aguardando pagamento',
  ABSENCE_CONSULTATION = 'Ausência na consulta',
  RESCHEDULED = 'Reagendado',
  MISSED = 'Faltou à consulta',
  CANCELED = 'Cancelou a consulta',
}
