<div align="right">
  <button
    mat-mini-fab
    mat-dialog-close
    type="button"
    class="red"
  >
    <fa-icon icon="times"></fa-icon>
  </button>
</div>

<h2 mat-dialog-title *ngIf="data.title">{{ data.title }}</h2>
<mat-dialog-content>
  <fa-icon icon="exclamation-circle" class="especial"></fa-icon>
  <p [innerHTML]="data.question"></p>

  <mat-form-field appearance="outline">
    <mat-select
      class="options"
      *ngIf="data.options"
      [value]="data.selected"
    >
      <mat-option *ngFor="let item of data.options" [value]="item">
      {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="data.textField">
    <mat-label>{{ data.textField.label }} </mat-label>
    <input
      matInput
      [(ngModel)]="data.textField.value"
    />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="data.cancel" mat-button (click)="cancel()" class="red">
    <fa-icon icon="times"></fa-icon> {{ data.cancel }}
  </button>

  <button mat-button (click)="action()" class="save">
    <fa-icon icon="check"></fa-icon> {{ data.confirm }}
  </button>
</mat-dialog-actions>
